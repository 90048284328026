import { mapActions } from 'vuex'

export default {
  data () {
    return {
      deleteItemsModalVisible: false
    }
  },
  methods: {
    ...mapActions([
      'actionDelete'
      ]),
    startRemoveAttachments () {
      this.actionDelete({uuids: this.selected, service: this.service, $notify: this.$notify})
      this.deleteItemsModalVisible = false
    },
    deleteFiles () {
      if (this.selected.length !== 0) {
        this.deleteItemsModalVisible = true
      } else {
        this.$notify({
          type: 'info',
          text: 'Nie ma zaznaczonych załączników do usunięcia'
        })
      }
    }
  }
}
