<template>
  <form>
    <select-type
      v-if="transitionsArrayLength > 1"
      ref="transitionSelect"
      v-model="value['selectedTransitionName']"
      v-validate="{ rules: {required: true}}"
      name="transition"
      label="Rodzaj tranzycji"
      :required="true"
      :disabled="!transitionsLoaded"
      :options="transitions"
      :empty-first="true"
      :error-message="vErrors.first('transition')"
      data-vv-as="Rodzaj tranzycji"
      @input="transitionChange"
    />
    <div v-if="transitions.length === 1">
      <p class="fake-label">
        Akcja:
      </p>
      <p class="fake-select-replacement">
        {{ transitions[0].label }}
      </p>
    </div>
    <div v-if="transitions.length === 0">
      <p class="">
      </p>
      <p class="">
        Brak dostępnych tranzycji
      </p>
    </div>
    <div v-if="customerWillDeliverDocumentationReminder"  class="customers-reminder">
      <p>Zawieszając zlecenie z powodu braku dokumentów upewnij się czy wymagane dokumenty nie zostały przekazane bezpośrednio do klienta.</p>
    </div>
    <div
      v-if="value['selectedTransitionName'] && showUserSelect"
      :id="'change-state-assignee-multiselect'"
      :class="[{ 'has-error': vErrors.first('responsible'), 'has-success' : !vErrors.first('responsible') && multiselectValue }, { 'required-field': vErrors.has('responsible') }]"

    >
      <template
        v-if="possibleUsersArrayLength > 1"
      >
        <label for="responsible">Osoba odpowiedzialna</label>
        <vue-multiselect
          :id="'input-change-state-assignee-multiselect'"
          v-validate="'required'"
          class="assignee-multiselect"
          :name="'responsible'"
          :value="multiselectValue"
          :label="'label'"
          :select-label="'Wybierz'"
          :selected-label="'Wybrano'"
          :deselect-label="'Odznacz'"
          :options="possibleUsers"
          data-vv-as="'Osoba odpowiedzialna'"
          :placeholder="'Wybierz/Szukaj'"
          :class="{'input': true, 'is-danger': vErrors.has('responsible') }"
          @input="handleMultiselectInput"
        >
          <template slot="noResult">
            <p>Brak wyników</p>
          </template>
        </vue-multiselect>
        <span class="help-block">{{ vErrors.first('responsible') }}</span>
      </template>
    </div>
    <select-type
      v-if="suggestedAppraiserExist"
      name='suggestedAppraiser'
      v-validate="{ rules: { required: true }}"
      label='Rzeczoznawca sugerowany'
      v-model="value['suggestedAppraiser']"
      :id="'suggestedAppraiser'"
      :options="options"
      :required="true"
      :disabled="!transitionsLoaded"
      :error-message="vErrors.first('suggestedAppraiser')"
      data-vv-as=" 'Rzeczoznawca sugerowany' "
      :empty-first="true"
      />

    <div v-if="possibleUsers.length === 1">
      <p class="fake-label">
        Opiekun:
      </p>
      <p class="fake-select-replacement">
        {{ possibleUsers[0].label }}
      </p>
    </div>
    <add-order-message
      v-if="selectedTransitionEvents.includes('add_message')"
      :eventName="'add_message'"
      :label="'Wiadomość reklamacyjna'"
      :service="service"
      :task-id="taskId"
      :filePath="filePath"
      v-model="value['add_message']"
      @filesLoaded="(uploadedFiles) => $emit('filesLoaded', uploadedFiles)"
      @deleteUploadedFile="(fileToDelete) => $emit('deleteUploadedFile', fileToDelete)"
      :files="files"
    ></add-order-message>
    <add-reject-order-comment
      v-if="selectedTransitionEvents.includes('reject_order')"
      :eventName="'reject_order'"
      :label="'Wiadomość'"
      v-model="value['reject_order']"
    >
    </add-reject-order-comment>
    <add-no-contact
      v-if="selectedTransitionEvents.includes('no_contact')"
      :eventName="'no_contact'"
      :label="'Wiadomość o braku kontaktu'"
      v-model="value['no_contact']"
    >
    </add-no-contact>
    <add-pro-forma
      v-if="isVisibleProForma"
      :isBeforeSubmit="isBeforeSubmit"
      :eventName="'pro_forma'"
      :label="'Pro forma (brutto)'"
      v-model="value.pro_forma"
      :service="service"
      :task-id="taskId.toString()" />
    <add-pricelist-details
      v-if="isVisiblePricelist"
      :isBeforeSubmit="isBeforeSubmit"
      :eventName="'pricelist_details'"
      :label="'Kwota netto'"
      v-model="value.pricelist_details"
      :service="service"
      :task-id="taskId"
      :responsible-person="multiselectValue" />
    <add-reasons
      v-if="selectedTransitionEvents.includes('cancel_order')"
      v-model="value['cancel_order']"
      :service="service"
    ></add-reasons>
    <add-local-vision-appointed
      :isBeforeSubmit="isBeforeSubmit"
      v-if="selectedTransitionEvents.includes('local_vision_appointed')"
      :eventName="'local_vision_appointed'"
      :label="'Data wizji lokalnej'"
      v-model="value['local_vision_appointed']"
    >
    </add-local-vision-appointed>
  </form>

</template>

<script>
import Loader from '../../../../../share/Loader.js'
import FormModalValidation from '../../../../../share/form/FormModalValidation.js'
import SelectType from '../../../../../share/form/type/SelectType.vue'
import VueMultiselect from 'vue-multiselect'
import AddReasons from "../components/AddReasons.vue";
import AddOrderMessage
  from "../components/AddOrderMessage.vue";
import AddRejectOrderComment
  from "../components/AddRejectOrderComment.vue";
import AddLocalVisionAppointed
  from "../components/AddLocalVisionAppointed.vue";
import AddNoContact from "../components/AddNoContact.vue";
import AddProForma from "../components/AddProForma.vue";
import AddPricelistDetails from "../components/AddPricelistDetails.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AddProForma,
    AddPricelistDetails,
    AddNoContact,
    AddLocalVisionAppointed,
    AddRejectOrderComment,
    AddOrderMessage,
    AddReasons,
    VueMultiselect,
    SelectType
  },
  mixins: [
    Loader,
    FormModalValidation
  ],
  props: {
    value: {},
    taskId: {required: true},
    workflowId: {type: Number || String, required: true},
    service: {type: String, required: true},
    client: {type: String, required: true},
    transitions: {type: Array, required: true, default: () => []},
    transitionsLoaded: {type: Boolean, required: true, default: () => false},
    possibleUsers: {type: Array, required: true, default: () => []},
    showUserSelect: {type: Boolean, required: true},
    selectedTransitionEvents: {type: Array, required: true, default: () => []},
    filePath: {type: String},
    files: {type: Array, default: () => []}
  },
  data() {
    return {
      selectedTransition: null,
      multiselectValue: null,
      rules: {
        required: v => !!v || 'Wiadomość jest wymagana'
      },
      options: [
        {label: 'Tak', value: true},
        {label: 'Nie', value: false}
      ],
      isVisiblePricelist: false
      }
  },
  computed: {
    possibleUsersArrayLength() {
      return this.possibleUsers.length
    },
    transitionsArrayLength() {
      return this.transitions.length
    },
    suggestedAppraiserExist() {
      const selectedTransition = this.transitions.find(transition => transition.value == this.selectedTransition)
      return selectedTransition && selectedTransition.metadata && selectedTransition.metadata.suggestedAppraiser
    },
    customerWillDeliverDocumentationReminder() {
      const selectedTransition = this.transitions.find(transition => transition.value == this.selectedTransition)
      return selectedTransition && selectedTransition.metadata && selectedTransition.metadata.customerWillDeliverDocumentation
    },
    isVisibleProForma() {
      const selectedTransition = this.transitions.find(transition => transition.value == this.value.selectedTransitionName)
      return selectedTransition && selectedTransition.metadata && selectedTransition.metadata.proForma || false
    },
    ...mapGetters(["getTransitions"]),
  },
  methods: {
    handleMultiselectInput(event) {
      if (event && event.value) {
        this.multiselectValue = event
        this.$emit('multiselectInput', event.value)
      } else {
        this.multiselectValue = ''
        this.$emit('multiselectInput', '')
      }
    },
    userChange(event) {
      let currentTransition = this.value['selectedTransitionName']
      this.$emit('input', {
        selectedTransitionName: currentTransition,
        selectedUserUuid: event
      })
    },
    transitionChange(event) {
      this.selectedTransition = event

      const currentTransition = this.getTransitions.find(transition => transition.value === Number(event))
      this.isVisiblePricelist = currentTransition?.metadata.pricelist

      this.$emit('changeTransition', event)
    },
  }
}
</script>
<style lang="scss" scoped>
.fake-label {
  text-align: left;
  color: #6ac13b;
  font-weight: bold;
}

.fake-select-replacement {
  text-align: left;
  font-weight: bold;
  margin-left: 20px;
}
.customers-reminder{
 line-height: 2rem;
 text-align: center;
 padding: 0.5rem 0;
 margin-bottom: 1.5rem;
 color: $mainColor;
}
</style>
