<template>
  <div>
    <text-area-type
      v-model="value['content']"
      :required="true"
      v-validate.initial="'required'"
      :label="label"
      :success="value.hasOwnProperty('content') && value['content'] && value['content'].length > 0"
      :data-vv-as="label"
      :error-message="vErrors.first(eventName)"
      :class="{'input': true, 'is-danger': vErrors.has(eventName)}"
      :name="eventName">
    </text-area-type>
    <form
      ref="form"
      class="attachments--file-form"
      enctype="multipart/form-data"
      @submit="(event) => event.preventDefault()"
    >
      <div class="row">
        <div class="col-xs-12 preview-uploaded-files-container">
          <div class="preview-file-upload-container" v-for="(file, index) in files" :key="`file-${index}`">
            <button class="preview-file-upload-delete" @click="() => handleDeleteItem(file)"
            >
              <i class="feather-slash"></i>
            </button>
            <span class="preview-file-upload-filename">{{file.name}}</span>
          </div>
        </div>
      </div>
      <div class="file-input-container">
      <button @click="clickInput" class="zord-btn btn zord-btn-primary btn-primary select-file-button" :class="{'btn-success': files.length !== 0}">Wybierz pliki</button>
        <span v-if="files.length !== 0">Ilość załadowanych plików: {{files.length}}</span>

        <input
        style="display: none"
        ref="file"
        type="file"
        class="attachments--file-input"
        multiple
        accept=".doc, .docx, .rtf, .pdf, .ods, .odt, .xls, .xlsx, .csv, .txt, .eml, .msg, .xlsm, .7z, .zip, .rar, .gz, .tar, .bmp, .jpeg, .jpg, .tif, .tiff, .png, .PNG, .gif, xml, .signPro, .SIGNPR, .DWG, .crw, .ath, .szf, .axx, .xades, .GPG, .pgp, .sig, .sko, .eie, .sie, .mp3"
        @change="submitFiles"
      >
      </div>
    </form>
  </div>
</template>
<script>
import TextAreaType from '@/components/share/form/type/TextAreaType.vue'

export default {
  name: 'AddOrderMessage',
  components: {TextAreaType},
  props: {
    value: {type: Object, required: true},
    eventName: {type: String, required: true},
    label: {type: String, required: true, default: () => ''},
    service: {
      type: String, default () {
        return ''
      }
    },
    filePath: {type: String, required: true},
    taskId: {required: true},
    files: {type: Array, default: () => []}
  },
  data () {
    return {
      rules: {
        required: v => !!v || `${this.label} jest wymagana`
      }
    }
  },
  methods: {
    clickInput(e) {
      e.preventDefault()
      this.$refs.file.click()
    },
    handleDeleteItem (file) {
      this.$emit('deleteUploadedFile', file)
    },
    submitFiles (event) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }
      this.$emit('filesLoaded', files)
    }
  }
}
</script>

<style>

.preview-uploaded-files-container::-webkit-scrollbar {
  width: 10px;
}

.preview-uploaded-files-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
.preview-uploaded-files-container::-webkit-scrollbar-thumb {
  background: #3779af;
}

/* Handle on hover */
.preview-uploaded-files-container::-webkit-scrollbar-thumb:hover {
  background: #26587e;
}

.preview-uploaded-files-container {
  height: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
}

.preview-file-upload-container {
  padding: 2px;
  display: flex;
  position: relative;
  justify-content: flex-start;
}

.preview-file-upload-delete {
  right: 0;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: deepskyblue;
  display: flex;
  justify-content: center;
}
.preview-file-upload-filename {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.select-file-button {
  width: 100px;
  height: 30px;
  margin: 5px;
}
.file-input-container {
  display: flex;
  align-items: center;
}
</style>
