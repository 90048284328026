<template>
  <section>
    <div class="add-local-vision-appointed-datetimepicker">
      <DateTimePickerType
        v-model="date"
        :config="datepickerSchema.dateTimePickerOptions"
        :label="'Data wizji'"
        v-validate="{ rules: { required: datepickerSchema.required } }"
        :required="true"
        name="date"
        data-vv-as="Data wizji"
        :error-message="vErrors.first('date')"
      ></DateTimePickerType>
      <DateTimePickerType
        v-model="time"
        :label="'Godzina wizji'"
        :config="hourPickerSchema.dateTimePickerOptions"
        v-validate="{ rules: { required: hourPickerSchema.required } }"
        :required="true"
        name="time"
        data-vv-as="Godzina wizji"
        :error-message="vErrors.first('time')"
      ></DateTimePickerType>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import DateTimePickerType from '@/components/share/form/type/DateTimePickerType.vue'
import FormModalValidation from '@/components/share/form/FormModalValidation.js'

Vue.use(VueFormGenerator)

export default {
  name: 'AddLocalVisionAppointed',
  components: {DateTimePickerType},
  mixins: [FormModalValidation],
  props: {
    value: {type: Object, required: true},
    eventName: {type: String, required: true},
    label: {type: String, required: true, default: () => ''}
  },
  watch: {
    date () {
      this.$emit('input', {...this.value, content: this.date + ' ' + this.time})
    },
    time () {
      this.$emit('input', {...this.value, content: this.date + ' ' + this.time})
    }
  },
  data () {
    return {
      date: '',
      time: '',
      datepickerSchema: {
        'type': 'dateTimePicker',
        'label': 'data wizji',
        'inputName': '',
        'disabled': false,
        'required': true,
        'relatedFields': [],
        'model': 'date',
        'readonly': false,
        validator: (value, field, model) => {
          return value ? [''] : [`Data wizji jest wymagana`]
        },

        'dateTimePickerOptions': {'format': 'YYYY-MM-DD', 'locale': this.$i18n.locale}
      },
      hourPickerSchema: {
        'type': 'dateTimePicker',
        'label': 'godzina wizji',
        'inputName': '',
        'disabled': false,
        'required': true,
        'relatedFields': [],
        'model': 'time',
        'readonly': false,
        validator: (value, field, model) => {
          return value ? [''] : [`Godzina wizji jest wymagana`]
        },
        'dateTimePickerOptions': {'format': 'HH:mm', 'locale': this.$i18n.locale}
      }
    }
  }
}
</script>
