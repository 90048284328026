import { mapActions } from 'vuex'

export default {
  data () {
    return {
      selected: [],
      lastSelectedAttachment: '',
    }
  },
  methods: {
    ...mapActions([
      'actionDownload'
    ]),
    toggleItem (uuid) {
      if (this.selected.includes(uuid)) {
        this.selected.splice(this.selected.indexOf(uuid), 1)
      } else {
        this.selected.push(uuid)
      }
    },
    toggleItemWithShift (uuid, insideRange) {
      const noop = function () {
        return
      }
      if (this.selected.includes(uuid)) {
        insideRange ? noop() : this.selected.splice(this.selected.indexOf(uuid), 1)
      } else {
        !insideRange ? noop() : this.selected.push(uuid)
      }
    },
    toggleItems (lastItemUuid, attachments) {
      if (this.selected.length !== 0) {
        let firstItem = {}
        let lastItem = {}
        attachments.forEach((attachment, index) => {
          if (attachment.id === this.lastSelectedAttachment) {
            firstItem = {index, attachment}
          }
          if (attachment.id === lastItemUuid) {
            lastItem = {index, attachment}
          }
        })

        let selectForward = lastItem.index - firstItem.index >= 0

        attachments.forEach((attachment, index) => {
          if (selectForward) {
            if(index >= firstItem.index && index <= lastItem.index) {
              this.toggleItemWithShift(attachment.id, true)
            } else {
              this.toggleItemWithShift(attachment.id, false)
            }
          } else {
            if(index <= firstItem.index && index >= lastItem.index) {
              this.toggleItemWithShift(attachment.id, true)
            } else {
              this.toggleItemWithShift(attachment.id, false)
            }
          }
        })
      }
    },
    downloadSelected () {
      if (this.selected.length > 1 && this.$store.state.base.user.isAttachmentPassword) {
        this.fileUuidsToEncrypt = this.selected
        this.openEncryptModal()
        return
      }
      this.actionDownload({uuids: this.selected, service: this.service, taskNumber: this.taskNumber, $notify: this.$notify})
    },
  }
}
