<template>
<div>
	<div class="pricelist">
		<money-type
			v-model="value.amount"
			v-validate="{ rules: { required: true }}"
			:clear="clear"
			:error-message="vErrors.first('amount')"
			:required="true"
			label="Pro forma (brutto)"
			name="amount"
			data-vv-value-path="value"
			data-vv-as="kwota brutto"
			class="pricelist-money-field"
			@input="onChangeAmount"
		/>
	</div>
</div>
</template>

<script>
import MoneyType from '@/components/share/form/type/MoneyType.vue'
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import {mapGetters} from 'vuex'

export default {
  name: "AddProForma",
  components: {
		MoneyType
		},
	mixins: [
    FormModalValidation
  ],
  props: {
    value: {type: Object, required: true},
    eventName: {type: String, required: true},
    label: {type: String, required: true, default: ''},
		service: {type: String, required: true},
		taskId: {type: String, required: true},
		responsiblePerson: {type: Object, default: null}
  },
  data() {
    return {
			clear: false,
			currentChecked: false,
			currentCheckedInvoice: false,
      rules: {
        required: v => !!v || `${this.label} jest wymagana`
      }
    }
  },
	computed: {
    ...mapGetters(["getCurrentUser"])
	},
	methods: {
		onSelectPrice (e) {
			if (!e.target.checked) {
				this.value.amount = ''
				this.currentChecked = ''
				return
			}

			this.value.amount = e.target.value;
			this.currentChecked = e.target.id
		},
		onSelectInvoice (e) {
			this.value.currentCheckInvoice = !e.target.checked ? '' : e.target.id;
		},
		onChangeAmount (e) {
			this.currentChecked = ''
		}
  }
}
</script>

<style lang="scss" scoped>
.pricelist{
	border-top: 1px solid #e8e8e8;
	padding-top: 1rem;
	margin-top: 2.5rem;
}

.pricelist-title,
.invoice-title {
	font-weight: bold;
	margin: 0;
}

.pricelist-details,
.invoice-details,
.pricelist-empty,
.invoice-empty {
	padding:  0.5rem 0 0 1.5rem;
	color: #42a5ff;
}

.invoice-empty,
.pricelist-empty {
	font-style: italic;
}

.invoice-completed-title {
	padding:  0.5rem 0 0 1.5rem;
	color: $successColor;
}

.invoice-in-progress {
	padding:  0.2rem 0 0 1rem;
}

.invoice-details-wrapper {
	list-style: none;
	margin-left: -10px;

	li {
		margin-bottom: .5rem;
	}

	.completed {
		margin-left: 1.5rem;
		color: #000;
	}
}

.pricelist-details-wrapper,
.invoice-details-wrapper {
	padding:  .2rem;
	color: #42a5ff;

	input:checked + label {
			font-weight: bold !important
	}

	& label{
		font-weight: normal;
	}

	input[type=checkbox] + label[data-v-b1564154]:before {
		margin-right: 2.5rem;
	}

	span {
		padding: 0 2px;
	}
}

.pricelist-money-field {
	margin-top: 1rem;
}

</style>
