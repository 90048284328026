<template>
  <form>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('reason'), 'has-success' : !vErrors.first('reason') }]"
    >
      <label>{{ $t('pages.taskDetails.comments.modal.cancelReason') }}</label>
      <VueMultiselect
        v-model="value['reason']"
        :multiple="true"
        name="reason"
        :options="cancelTransitionReasons"
        :placeholder="$t('pages.taskDetails.comments.modal.cancelReason')"
        :deselectLabel="$t('pages.taskDetails.comments.modal.cancelReasonMultiselect.deselect')"
        :selectLabel="$t('pages.taskDetails.comments.modal.cancelReasonMultiselect.select')"
        :label="'label'"
        :track-by="'value'"
        v-validate.initial="{rules: {required: true}}"
        :data-vv-as="$t('pages.taskDetails.comments.modal.cancelReason')"
      ></VueMultiselect>
      <span
        v-show="vErrors.has('reason')"
        class="help-block"
      >{{ vErrors.first('reason') }}</span>
    </div>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('reasons-message'), 'has-success' : !vErrors.first('reasons-message') }]"
    >
      <label for="reasons-message">
        Treść odrzucenia
      </label>
      <textarea
        id="reasons-message"
        v-model="value['content']"
        v-validate.initial="{rules: {required: true, max: 1024}}"
        name="reasons-message"
        class="form-control"
        cols="60"
        rows="6"
        :placeholder="$t('pages.taskDetails.comments.modal.placeholder')"
        :data-vv-as="$t('pages.taskDetails.comments.modal.dataVvAs')"
      />
      <span
        v-show="vErrors.has('reasons-message')"
        class="help-block"
      >{{ vErrors.first('reasons-message') }}</span>
    </div>
  </form>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import api from '@/api'
import ErrorNotify from '@/components/share/mixins/ErrorNotify.js'

export default {
  components: {
    VueMultiselect
  },
  mixins: [
    ErrorNotify
  ],
  props: {
    value: {type: Object, required: true},
    service: {type: String, required: true}
  },
  mounted () {
    this.getReasons()
  },
  data () {
    return {
      cancelTransitionReasons: [
        {value: 'IncorrectOrderData', label: 'Błędne dane w zleceniu / błędna opłata'},
        {value: 'MissingDocuments', label: 'Braki w dokumentacji'},
        {value: 'NoInspectionPossible', label: 'Brak możliwości przeprowadzenia oględzin'},
        {velue: 'CannotBeCollateral', label: 'Nieruchomość nie może stanowić zabezpieczenia kredytu bankowego'},
        {value: 'CustomerCancelled', label: 'Rezygnacja klienta'}
      ]
    }
  },
  methods: {
    async getReasons () {
      try {
        const response = api.request(this.service, 'get', '/cancel-order-reasons')
        const {data} = await response
        this.cancelTransitionReasons = Object.entries(data).map(([key, value]) => ({value: key, label: value}))
      } catch (e) {
        this.errorNotify(e)
      }
    }
  }
}
</script>
