<template>
  <form @submit.prevent="() => {}">
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('message'), 'has-success' : !vErrors.first('message') && value['message'] }]"
    >
      <input-type
        v-for="field in formFields"
        :key="field.name"
        v-model="value[field.name]"
        v-validate="{ rules: field.rules }"
        :data-vv-value-path="'value'"
        :data-vv-as="field.label2 ? field.label2 : field.label"
        :error-message="vErrors.first(field.name)"
        :label="field.label"
        :name="field.name"
        :required="field.rules.required"
        :type="'password'"
      />
      <span
        v-show="vErrors.has('message')"
        class="help-block"
      >{{ vErrors.first('message') }}</span>
    </div>
  </form>
</template>

<script>
  import FormModalValidation from '../../../../../share/form/FormModalValidation'
  import InputType from '../../../../../share/form/type/InputType'
  import {Validator} from 'vee-validate'

  export default {
    components: {InputType},
    mixins: [
      FormModalValidation
    ],
    props: {
      value: { type: Object, required: true }
    },
    data () {
      return {
        formFields: [
          { name: 'password', label: 'Hasło', rules: { required: true, password_form: true } },
          { name: 'password2', label: 'Powtórz hasło', rules: { required: true, repeated_password: true } }
        ],
      }
    },
    created () {
      Validator.extend('repeated_password', {
        getMessage: () => 'Hasła muszą być jednakowe.',
        validate: value => value === this.value.password
      })
      Validator.extend('password_form', {
        getMessage: () => 'Hasło musi zawierać min.4 znaki',
        validate: value => (/\w{4,}/.test(value))
      })
    }
  }
</script>
