export default {
  props: {},
  data () {
    return {
      thirdMenu: []
    }
  },
  created () {
    if (this.filePath) {
      this.getButtons()
    }
  },
  methods: {
    '$showThirdMenu' (tab) {
      if (tab === 'attachments') {
        this.$events.$emit('dashboard:menu:taskTab', this.thirdMenu)
      }
    },
    getButtons () {
      if (this.isAllowedToEdit) {
        this.thirdMenu = [
          {
            event: { name: `${this.service}:${this.taskType}:uploadAttachments` },
            icon: 'save',
            label: 'Dodaj załączniki',
            acl: { security: false }
          },
          {
            event: { name: `task:attachments:invertSelection` },
            icon: 'check-square',
            label: 'Odwróć zaznaczenie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadSelectedAttachments` },
            icon: 'download',
            label: 'Pobierz zaznaczone',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadAttachments` },
            icon: 'download',
            label: 'Pobierz wszystkie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:moveAttachments` },
            icon: 'settings',
            label: 'Przenieś pliki',
            acl: { service: [this.service], action: 'put_attachments_move' }
          },
          {
            event: { name: `${this.service}:${this.taskType}:removeSelectedAttachments` },
            icon: 'trash-2',
            label: 'Usuń zaznaczone',
            acl: { service: [this.service, 'storage'], action: 'delete_files' }
          },
          // {
          //   event: { name: `${this.service}:${this.taskType}:rotateAttachments` },
          //   icon: 'trash-2',
          //   label: 'Obróć o 90 stopni',
          //   acl: { service: [this.service, 'storage'], action: 'put_files' }
          // },
          {
            event: { name: `${this.service}:${this.taskType}:sendAttachmentToAudanet` },
            icon: 'skip-forward',
            label: 'Wyślij do Audanet',
            acl: { service: this.service, action: 'post_audanet_attachment' }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: 'settings',
            label: 'Pokaż wszystkie załączniki',
            acl: { security: false }
          }
        ]
      } else {
        this.thirdMenu = [
          {
            event: { name: `task:attachments:invertSelection` },
            icon: 'check-square',
            label: 'Odwróć zaznaczenie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadSelectedAttachments` },
            icon: 'download',
            label: 'Pobierz zaznaczone',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadAttachments` },
            icon: 'download',
            label: 'Pobierz wszystkie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:sendAttachmentToAudanet` },
            icon: 'skip-forward',
            label: 'Wyślij do Audanet',
            acl: { service: this.service, action: 'post_audanet_attachment' }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: 'settings',
            label: 'Pokaż wszystkie załączniki',
            acl: { security: false }
          }
        ]
      }
    },
    getViewModeButtons () {
      if (this.isAllowedToEdit) {
        this.thirdMenu = [
          {
            event: { name: `${this.service}:${this.taskType}:uploadAttachments` },
            icon: 'save',
            label: 'Dodaj załączniki',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadSelectedAttachmentsViewMode` },
            icon: 'download',
            label: 'Pobierz zaznaczone',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadAttachments` },
            icon: 'download',
            label: 'Pobierz wszystkie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:removeSelectedAttachmentsViewMode` },
            icon: 'trash-2',
            label: 'Usuń zaznaczone',
            acl: { service: [this.service, 'storage'], action: 'delete_files' }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: 'settings',
            label: 'Pokaż według kategorii',
            acl: { security: false }
          }
        ]
      } else {
        this.thirdMenu = [
          {
            event: { name: `${this.service}:${this.taskType}:downloadSelectedAttachmentsViewMode` },
            icon: 'download',
            label: 'Pobierz zaznaczone',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadAttachments` },
            icon: 'download',
            label: 'Pobierz wszystkie',
            acl: { security: false }
          },
          {
            event: { name: `toggleAttachmentsView` },
            icon: 'settings',
            label: 'Pokaż według kategorii',
            acl: { security: false }
          }
        ]
      }
    }
  }
}
