export default {
  data () {
    return {
      securityActions: []
    }
  },
  computed: {
    isAllowedToEdit () {
      if (this.$store.state.base.user.roles.includes('ROLE_ADMIN')) {
        return true
      }
      if (this.$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN')) {
        return true
      }

      if (this.taskType === 'appraisal') {
        if (this.taskState.name === 'zrealizowane') {
          return this.blockAppraiser()
        } else {
          return this.blockFinal()
        }
      } else {
        return this.blockAppraiser() && this.blockFinal()
      }
    }
  },
  mounted () {
  },
  methods: {
    blockAppraiser () {
      return !(this.$store.state.base.user.roles.includes(
        'ROLE_RZECZOZNAWCA_BLS') ||
        this.$store.state.base.user.roles.includes(
          'ROLE_RZECZOZNAWCA_ZEWN'))
    },
    blockFinal () {
      return true
    },
    containsHiddenCategoryAction (categoryName) {
      switch (parseInt(categoryName)) {
        case 8:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_payment_confirmation_category'})
        case 9:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_order_printing_category'})
        case 7:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_operat_category'})
        case 11:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_complaint_category'})
        case 13:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_estimate_category'})
        default:
          return true
      }
    }
  }
}
