import api from '../../../../../../api'
import errorReporter from '../../../../../../store/attachmentsState/utils/errorReporter'

export default {
  data () {
    return {
      sortedBySections: []
    }
  },
  computed: {
    sortedAndGroupedCategories () {
      const sorting = {
        defaultConf: {
          withoutCategory: [null],
          main: [6, 7, 29, 8, 3, 1],
          additional: [2, 5, 4, 11, 32],
          rest: [9, 16, 10, 14, 13, 12]
        },
        intbwn: {
          data: {
            withoutCategory: [null],
            main: [1, 2, 3, 4, 5, 6],
            additional: [7, 8, 9, 10, 11],
            rest: [12]
          }
        },
      }
      const parseCategories = (data) => {
        return Object.values(data)
          .map(group =>
            group.map(catId => this.categories.find(cat => cat.id === catId) ? this.categories.find(cat => cat.id === catId) : {})
              .filter(el => el.hasOwnProperty('id')))
      }
      return !this.$auth.isAllowed({service: this.service, action: 'get_attachment_category_sections'}) ?
        (sorting.hasOwnProperty(this.service) ? parseCategories(sorting[this.service].data) : parseCategories(sorting['defaultConf']))
        : this.sortedBySections
    }
  },
  methods: {
    async getSections () {
      try {
        const {data} = await api.request(this.service, 'GET', '/attachment-categories/sections')
        const sections = []
        const nullCat = this.categories.find(cat => cat.id === null)
        sections.push([nullCat])
        data.forEach(section => sections.push(this.categories.filter(category  => category.categorySectionId === section.id)))
        this.sortedBySections = sections
      } catch (e) {
        errorReporter.reportError(e)
      }
    }
  }
}
