<template>
  <text-area-type
    v-model="value['content']"
    :required="true"
    v-validate.initial="'required'"
    :label="label"
    :success="value.hasOwnProperty('content') && value['content'] && value['content'].length > 0"
    :data-vv-as="label"
    :error-message="vErrors.first(eventName)"
    :class="{'input': true, 'is-danger': vErrors.has(eventName)}"
    :name="eventName">
  </text-area-type>
</template>

<script>
import TextAreaType from "@/components/share/form/type/TextAreaType.vue";
export default {
  name: "AddRejectOrderComment",
  components: {TextAreaType},
  props: {
    value: {type: Object, required: true},
    eventName: {type: String, required: true},
    label: {type: String, required: true, default: () => ''}
  },
  data() {
    return {
      rules: {
        required: v => !!v || `${this.label} jest wymagana`
      }
    }
  }
}
</script>

<style scoped>

</style>
