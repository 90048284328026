<template>
<div>
	<div class="invoice">
		<p class="invoice-title">Dane finansowe</p>
		<div class="invoice-empty" v-if="noInvoicesData">Nie ma jeszcze danych finansowych</div>

		<div class="invoice-completed" v-if="filterInvoiceDataCompleted.length">
			<ul class="invoice-details-wrapper form-check">
				<li class="invoice-completed-title">Zlecenie nr {{invoiceNumber}} zostało zrealizowane:</li>
				<li class="form-check-label completed" v-for="item in filterInvoiceDataCompleted" :key="item.id">
					Faktura nr: {{item.invoiceNumber}},
					<span class="form-check-amount">kwota: {{item.amount}}</span>zł
				</li>
			</ul>
		</div>

		<div class="invoice-in-progress" v-else>
			<div class="invoice-details" v-for="item in filterInvoiceDataInProgress" :key="item.id">
				<div class="invoice-details-wrapper form-check">
					<input
						class="form-check-input"
						type="checkbox"
						:value="item.amount"
						:id="item.id"
						name="inlineRadioOptions"
						:checked="value.currentCheckInvoice == item.id"
						@input="onSelectInvoice"
					>
					<label class="form-check-label" :for="item.id">
						Zlecenie: {{invoiceNumber}},
						<span class="form-check-amount">kwota: {{item.amount}}</span>zł
					</label>
				</div>
			</div>
		</div>
	</div>

	<div class="pricelist" v-if="responsiblePerson">
		<p class="pricelist-title">Dane cennika</p>
		<div class="pricelist-empty" v-if="!pricelistAmount.residentialFinished && !pricelistAmount.residentialInProgress && !pricelistAmount.buildingFinished && !pricelistAmount.buildingInProgress && !pricelistAmount.plot">Brak podpiętych cenników</div>
		<div class="pricelist-details" v-for=" item in prices" :key="item.name">
			<div class="pricelist-details-wrapper form-check" v-if="item.price">
				<input
					class="form-check-input"
					type="checkbox"
					:value="item.price"
					:id="item.name"
					name="inlineRadioOptions"
					:checked="currentChecked == item.name"
					@input="onSelectPrice"
				>
				<label class="form-check-label" :for="item.name">
					{{item.label}}:
					<span class="form-check-amount">{{item.price}}</span>zł
				</label>
			</div>
		</div>
		<money-type
			v-model="value.amount"
			v-validate="{ rules: { required: true }}"
			:clear="clear"
			:error-message="vErrors.first('amount')"
			:required="true"
			label="Kwota netto"
			name="amount"
			data-vv-value-path="value"
			data-vv-as="kwota netto"
			class="pricelist-money-field"
			@input="onChangeAmount"
		/>
		<text-area-type
			v-model="value.content"
			:required="false"
			label="Opis"
			:success="value.hasOwnProperty('content') && value['content'] && value['content'].length > 0"
			:data-vv-as="label"
			:error-message="vErrors.first(eventName)"
			:name="eventName"
			placeholder="Wpisz treść... Opis nie może przekroczyć 512 znaków"
			maxlength="512"
			>
		</text-area-type>
	</div>
</div>
</template>

<script>
import TextAreaType from '@/components/share/form/type/TextAreaType.vue'
import MoneyType from '@/components/share/form/type/MoneyType.vue'
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import api from '@/api'
import {mapGetters} from 'vuex'

export default {
  name: "AddPricelistDetails",
  components: {
		TextAreaType,
		MoneyType
		},
	mixins: [
    FormModalValidation
  ],
  props: {
    value: {type: Object, required: true},
    eventName: {type: String, required: true},
    label: {type: String, required: true, default: ''},
		service: {type: String, required: true},
		taskId: {type: String, required: true},
		responsiblePerson: {type: Object, default: null}
  },
  data() {
    return {
			kind:'',
			pricelistAmount: {
				residentialFinished: null,
				residentialInProgress: null,
				buildingFinished: null,
				buildingInProgress: null,
				plot: null
			},
			invoiceNumber: '',
			filterInvoiceDataInProgress: [],
			filterInvoiceDataCompleted: [],
			clear: false,
			currentChecked: false,
			currentCheckedInvoice: false,
      rules: {
        required: v => !!v || `${this.label} jest wymagana`
      }
    }
  },
	created () {
    this.getInvoiceDetails()
  },
	watch: {
		responsiblePerson() {
			this.getPricelistAmount()
		}
	},
	computed: {
    ...mapGetters(["getCurrentUser"]),
		noInvoicesData () {
			return !this.filterInvoiceDataInProgress.length && !this.filterInvoiceDataCompleted.length
		},
		prices () {
			return [
				{ name: 'residentialFinished', label: 'Lokal gotowy', price: this.pricelistAmount.residentialFinished },
				{ name: 'residentialInProgress', label: 'Lokal w budowie', price: this.pricelistAmount.residentialInProgress },
				{ name: 'buildingFinished', label: 'Budynek gotowy', price: this.pricelistAmount.buildingFinished },
				{ name: 'buildingInProgress', label: 'Budynek w budowie', price: this.pricelistAmount.buildingInProgress },
				{ name: 'plot', label: 'Grunt', price: this.pricelistAmount.plot },
      ]
		}
	},
	methods: {
    async getPricelistAmount () {
			try {
				const response = api.request('finance', 'get', `/appraiser-price-list?service=${this.service}&uuid=${this.responsiblePerson.value}`)
        const {data} = await response

				const isOnlyOneType = Object.values(data).filter(Boolean).length

				if (isOnlyOneType === 1) {
					const [dataKey, dataValue] = Object.entries(data).find(typeData => typeData[1])

					this.currentChecked = dataKey
					this.value.amount = dataValue
				}

				this.kind = this.$store.state.base.currentTaskModel.realEstates[0].kind
				if(this.kind === 'House') {
					this.pricelistAmount.buildingFinished = data.buildingFinished
					this.pricelistAmount.buildingInProgress = data.buildingInProgress
				} else if(this.kind === 'Residential') {
					this.pricelistAmount.residentialFinished = data.residentialFinished
					this.pricelistAmount.residentialInProgress = data.residentialInProgress
				} else if(this.kind === 'Plot') {
					this.pricelistAmount.plot = data.plot
				}

      } catch (e) {
        this.errorNotify(e)
      }
    },
		async getInvoiceDetails () {
			try {
				let url = this.$isWithClients(this.service) ? `invoices?taskId=${this.taskId}` : `tasks/${this.taskId}/invoices`

				const response = api.request(this.service, 'get', url)
				const { data } = await response

				this.invoiceNumber = Object.keys(data)[0]
				const arr = Object.values(data)[0]

				if (arr) {
					this.filterInvoiceDataCompleted = arr.filter((el) => el.date && el.invoiceNumber)
					this.filterInvoiceDataInProgress = arr.filter((el) => !(el.date && el.invoiceNumber))
				}


				const isOnlyOneTypeInvoice = Object.values(data)[0].filter(Boolean).length

				if (isOnlyOneTypeInvoice === 1) {
					const [dataKey] = Object.values(Object.values(data)[0])

					this.value.currentCheckInvoice = dataKey.id
				}

			} catch (e) {
        console.log('Nie można załadować danych finansowych', e)
			}
		},
		onSelectPrice (e) {
			if (!e.target.checked) {
				this.value.amount = ''
				this.currentChecked = ''
				return
			}

			this.value.amount = e.target.value;
			this.currentChecked = e.target.id
		},
		onSelectInvoice (e) {
			this.value.currentCheckInvoice = !e.target.checked ? '' : e.target.id;
		},
		onChangeAmount (e) {
			this.currentChecked = ''
		}
  }
}
</script>

<style lang="scss" scoped>
.pricelist{
	border-top: 1px solid #e8e8e8;
	padding-top: 1rem;
	margin-top: 2.5rem;
}

.pricelist-title,
.invoice-title {
	font-weight: bold;
	margin: 0;
}

.pricelist-details,
.invoice-details,
.pricelist-empty,
.invoice-empty {
	padding:  0.5rem 0 0 1.5rem;
	color: #42a5ff;
}

.invoice-empty,
.pricelist-empty {
	font-style: italic;
}

.invoice-completed-title {
	padding:  0.5rem 0 0 1.5rem;
	color: $successColor;
}

.invoice-in-progress {
	padding:  0.2rem 0 0 1rem;
}

.invoice-details-wrapper {
	list-style: none;
	margin-left: -10px;

	li {
		margin-bottom: .5rem;
	}

	.completed {
		margin-left: 1.5rem;
		color: #000;
	}
}

.pricelist-details-wrapper,
.invoice-details-wrapper {
	padding:  .2rem;
	color: #42a5ff;

	input:checked + label {
			font-weight: bold !important
	}

	& label{
		font-weight: normal;
	}

	input[type=checkbox] + label[data-v-b1564154]:before {
		margin-right: 2.5rem;
	}

	span {
		padding: 0 2px;
	}
}

.pricelist-money-field {
	margin-top: 1rem;
}

</style>
