

export default {
  data () {
    return {
      mouseOverAttachment: '',
      tooltipVisible: false,
      tooltipClass: 'attachment-tooltip'
    }
  },
  methods: {
    showTooltip (e, id) {

      this.mouseOverAttachment = id
      this.tooltipClass = 'tooltipVisible'
      this.setTooltipPosition(e)
    },
    hideTooltip (id) {
      this.tooltipClass = 'attachment-tooltip'
      this.mouseOverAttachment = ''
    },
    getItemPosition (element, event) {
      return event.relatedTarget.getBoundingClientRect(element)
    },
    getItemPositionRight (e) {
      return window.innerWidth - this.getItemPosition('.attachment-tooltip', e).right
    },
    getTooltipWidth (e) {
      return this.getItemPosition('.attachment-tooltip', e).width
    },
    setTooltipPosition (e) {
      if (this.getItemPositionRight(e) < this.getTooltipWidth(e) * 4) {
        this.tooltipClass = 'tooltipVisibleRight'
      } else if (this.getItemPositionRight(e) > this.getTooltipWidth(e)) {
        this.tooltipClass = 'tooltipVisible'
      }
    }
  }
}
